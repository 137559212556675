<template>
  <OutsideClickContainer @outsideClick="isOpen = false" :active="isOpen">
    <div class="select-container">
      <InputElement
        v-model="selected.value"
        :placeholder="placeholder"
        @input="handleSearch"
        @blur="emitInput"
        autocomplete="off"
        validation-rules="required"
      />
      <div class="select-dropdown-container">
        <div v-if="isOpen" class="select-dropdown">
          <div v-if="!multiSelect" class="options-list">
            <div
              v-for="(item, i) in options.predictions"
              @click="selectItem(item)"
              role="button"
              tabindex="0"
              :key="i"
              class="options-item"
            >
              <slot name="list-label" :value="item"></slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </OutsideClickContainer>
</template>

<script>
import { onMounted, ref, watch, reactive } from '@vue/composition-api';
import CarrotDownWide from '../../Svgs/Carrots/CarrotDownWide.vue';
import useSingleSelect from './composables/useSingleSelect';
import InputElement from '@/components/FormControl/InputElement.vue';
import OutsideClickContainer from '../../OutsideClickContainer/OutsideClickContainer.vue';
import { capitalize } from '../../../utils/helpers';

export default {
  setup(props, { emit }) {
    const isOpen = ref(false);
    const [selected, selectSingleItem] = useSingleSelect(isOpen, emit);
    selected.value = {
      value: '',
    };
    const service = new window.google.maps.places.AutocompleteService();
    let options = reactive({
      predictions: [],
    });

    const getOptionLabel = val => {
      return capitalize(val ? val : props.placeholder);
    };

    onMounted(() => {
      if (!Array.isArray(props.value) && props.value) {
        selected.value = {
          value: props.value.toString(),
        };
      }
    });

    watch(isOpen, (newIsOpen, oldIsOpen) => {
      if (!newIsOpen && oldIsOpen) {
        emit('dropdown-closed', isOpen.value);
      }
    });

    watch(
      () => props.value,
      newVal => {
        if (!Array.isArray(newVal)) {
          selected.value = {
            value: newVal.toString(),
            // label: getOptionLabel(newVal),
          };
        }
      }
    );
    const getPlaces = async query => {
      const res = await service.getPlacePredictions({
        input: query,
        types: [props.searchType],
      });
      options.predictions = res.predictions;
    };

    const handleSearch = value => {
      isOpen.value = true;
      getPlaces(value);
    };

    const emitInput = value => {
      emit('select', {
        structured_formatting: {
          main_text: selected.value.value,
        },
      });
    };
    const selectItem = item => {
      emit('select', item);
      isOpen.value = false;
    };
    return {
      isOpen,
      selectSingleItem,
      selected,
      handleSearch,
      selectItem,
      options,
      emitInput,
    };
  },
  components: {
    CarrotDownWide,
    OutsideClickContainer,
    InputElement,
  },
  props: {
    value: {
      type: [Number, String, Array],
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Wähle eine Option',
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    searchType: {
      type: String,
      default: 'geocode',
    },
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  position: relative;
  width: 100%;
}
.select-input {
  width: 100%;
  height: 44px;
  border: $hty-border;
  border-radius: $hty-radius;
  outline: none;
  padding-left: 0.9rem;
  padding-right: 2.5rem;
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.2;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.grey {
    background-color: $hty-grey;
    border: 1px solid $hty-grey;
  }

  &.touched {
    border-color: $hty-red2;
  }
  &.active {
    border-color: $hty-red2;
  }
  &.passed {
    border-color: $hty-grey-border;
  }

  > .icon {
    position: absolute;
    right: 16px;
    top: 10px;
  }
}
.select-dropdown-container {
  width: 100%;
  border-radius: $hty-radius;
  background: $hty-white;
  box-shadow: $hty-shadow-dropdown;
  position: absolute;
  top: 50px;
  z-index: $z-index-dropdowns;
}
.select-dropdown {
  max-height: 200px;
  overflow-y: auto;
  outline: none;
}
.options-list {
}
.options-item {
  padding: 0.5rem 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
  color: $hty-dark-grey;
  cursor: pointer;
  padding-left: 0.9rem;
  outline: none;
  display: flex;

  > :first-child {
    margin-right: 1.5rem;
  }

  &.multi {
    cursor: initial;
  }
}
.selection {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
}
.selection-empty {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2;
}
.search {
  display: none;
  height: 44px;

  &.active {
    display: block;
  }

  ::v-deep .input {
    border: none;
    background-color: $hty-white;
    border-bottom: 1px solid $hty-orange;
    border-radius: initial;
  }
}
</style>
