<template>
  <div class="company-logo">
    <input
      type="file"
      ref="uploadAvatarInput"
      style="display: none"
      @change="handleFileChange($event.target.files[0])"
    />
    <div class="img-container">
      <img
        v-if="$store.getters.userInfo.avatar"
        :src="$store.getters.userInfo.avatar"
      />
      <img v-else :src="require('@/assets/pics/default_avatar.png')" />
      <!-- <SlackIcon v-else :size="98" /> -->
    </div>
    <span class="camera-icon" @click="uploadAvatarInput.click()">
      <CameraIcon />
    </span>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import SlackIcon from '../Svgs/SlackIcon';
import CameraIcon from '../Svgs/CameraIcon';
import store from '../../store';
export default {
  setup() {
    const uploadAvatarInput = ref(null);

    const handleFileChange = async file => {
      let formData = new FormData();
      formData.append('avatar', file);
      await store.dispatch('uploadAvatar', formData);
      store.commit('setShowSuccessfulActionModal', true);
    };

    return {
      uploadAvatarInput,
      handleFileChange,
    };
  },
  components: {
    SlackIcon,
    CameraIcon,
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  width: 98px;
  height: 98px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
